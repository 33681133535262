import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import 'element-plus/lib/theme-chalk/index.css';
import VueUeditorWrap from 'vue-ueditor-wrap';
import '../public/iconfont/iconfont.css'
// import 'element-plus/packages/theme-chalk/src/base.scss'
import {
    ElAlert,
    ElAside,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    ElContainer,
    ElMain,
    ElHeader,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElSubmenu,
    ElCard,
    ElCollapse,
    ElCollapseItem,
    ElTree,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElTabs,
    ElTabPane,
    ElTable,
    ElTableColumn,
    ElTag,
    ElSelect,
    ElOption,
    ElUpload,
    ElRadio,
    ElRadioGroup,
    ElDivider,
    ElDatePicker,
    ElCascader,
    locale,
    ElDialog,
    ElSwitch,
    ElEmpty,
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
    ElBacktop,
    ElCheckbox,
    ElCheckboxGroup,
    ElCollapseTransition,
    ElPagination,
    ElDescriptions,
    ElDescriptionsItem,
    ElDropdownItem,
    ElDropdownMenu,
    ElDropdown,
} from 'element-plus'


import lang from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import axios from "axios";
import VueAxios from 'vue-axios'

locale(lang)
// axios.defaults.baseURL = 'http://82.156.212.22:55511/'
axios.defaults.baseURL = 'https://yzmp.nkyz.vip'
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
    // console.log(config)
    config.headers.authorization = store.state.Token
    return config;
}, err => {
    return Promise.reject(err);
});
const app = createApp(App)
app.use(VueAxios, axios)
const components = [
    ElAlert,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    ElContainer,
    ElAside,
    ElMain,
    ElHeader,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElSubmenu,
    ElCard,
    ElCollapse,
    ElCollapseItem,
    ElTree,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElTabs,
    ElTabPane,
    ElTable,
    ElTableColumn,
    ElTag,
    ElSelect,
    ElOption,
    ElUpload,
    ElRadio,
    ElRadioGroup,
    ElDivider,
    ElDatePicker,
    ElCascader,
    ElDialog,
    ElSwitch,
    ElEmpty,
    ElBacktop,
    ElCheckbox,
    ElCheckboxGroup,
    ElCollapseTransition,
    ElPagination,
    ElDescriptions,
    ElDescriptionsItem,
    ElDropdownItem,
    ElDropdownMenu,
    ElDropdown
]
const plugins = [
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
]
components.forEach(component => {
    app.component(component.name, component)
})
plugins.forEach(plugin => {
    app.use(plugin)
})
app.use(VueUeditorWrap)
app.use(store).use(router).mount('#app')
