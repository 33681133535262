import { createRouter, createWebHashHistory } from 'vue-router'
const Login = () => import(/* webpackChunkName: "index" */ '../components/login/login.vue')
const Home = () => import(/* webpackChunkName: "index" */ '../components/index/index.vue')
const indexCom = () => import(/* webpackChunkName: "index" */ '../components/Home/home.vue')

const Goods = () => import(/* webpackChunkName: "goods" */ '../components/Goods/Goods.vue')
const SortMange = () => import(/* webpackChunkName: "goods" */ '../components/Goods/SortMange/SortMange.vue')
const GoodsList = () => import(/* webpackChunkName: "goods" */ '../components/Goods/GoodsList/GoodsList.vue')
const AddGoods = () => import(/* webpackChunkName: "goods" */ '../components/Goods/AddGoods/AddGoods.vue')

const UpGoods = () => import(/* webpackChunkName: "upgoods" */ '../components/upGoods/upGoods.vue')
const UpGoodsList = () => import(/* webpackChunkName: "upgoods" */ '../components/upGoods/upGoodsList/upGoodsLIst.vue')
const UpeditGoodsInfo = () => import(/* webpackChunkName: "upgoods" */ '../components/upGoods/editupGoodsInfo/editUpGoodsInfo.vue')

const TeamMange = () => import(/* webpackChunkName: "active" */ '../components/TeamPurse/TeamMange.vue')
const TeamInfo = () => import(/* webpackChunkName: "active" */ '../components/TeamPurse/teaminfo/TeamInfo.vue')
const AddTeam = () => import(/* webpackChunkName: "active" */ '../components/TeamPurse/AddTeam/AddTeam.vue')
const GoodsMange = () => import(/* webpackChunkName: "active" */ '../components/TeamPurse/goodsMange/goodsMange.vue')

// const LiveMange = () => import(/* webpackChunkName: "active" */ '../components/LivePurse/LiveMange.vue')
const LiveList = () => import(/* webpackChunkName: "active" */ '../components/LivePurse/LiveList/LiveList.vue')
const Livegood = () => import(/* webpackChunkName: "active" */ '../components/LivePurse/Livegood/Livegood.vue')
const LiveGoodsMange = () => import(/* webpackChunkName: "active" */ '../components/LivePurse/goodsMange/goodsMange.vue')

const ProfessionMange = () => import(/* webpackChunkName: "profession" */ '../components/profession/ProfessionMange.vue')
const ProfessionList = () => import(/* webpackChunkName: "profession" */ '../components/profession/professionList/ProfessionList.vue')
const changeProfess = () => import(/* webpackChunkName: "profession" */ '../components/profession/changeProfess/changeProfess.vue')
const preMange = () => import(/* webpackChunkName: "profession" */ '../components/profession/pretouch/preMange.vue')

const OrderForm = () => import(/* webpackChunkName: "order" */ '../components/OrderForm/OrderForm.vue')
const Order = () => import(/* webpackChunkName: "order" */ '../components/OrderForm/Order/Order.vue')
const OrderDetaile = () => import(/* webpackChunkName: "order" */ '../components/OrderForm/OrderDetail/OrderDetail.vue')

const authorzationMange = () => import(/* webpackChunkName: "authorzation" */ '../components/authorzationMange/AuthorzationMange.vue')
const AuthorzationList = () => import(/* webpackChunkName: "authorzation" */ '../components/authorzationMange/AuthorzationList/AuthorzationList.vue')
const cancleorderAuthorzationList = () => import(/* webpackChunkName: "authorzation" */ '../components/authorzationMange/AuthorzationList/cancleorderAuthorzationList.vue')
const cancleorderitem = () => import(/* webpackChunkName: "authorzation" */ '../components/authorzationMange/AuthorzationList/cancleorderitem.vue')
const cancledetaile = () => import(/* webpackChunkName: "authorzation" */ '../components/authorzationMange/AuthorzationList/cancledetaile.vue')

const SwiperMange = () => import(/* webpackChunkName: "homepage" */ '../components/Swiper/SwiperMange.vue')
const SwiperInfo = () => import(/* webpackChunkName: "homepage" */ '../components/Swiper/swiperInfo/SwiperInfo.vue')
const changeSwiper = () => import(/* webpackChunkName: "homepage" */ '../components/Swiper/changeSwiper/changeSwiper.vue')

// const AdMange = () => import(/* webpackChunkName: "homepage" */ '../components/Ad/AdMange.vue')
const AdInfo = () => import(/* webpackChunkName: "homepage" */ '../components/Ad/AdInfo/AdInfo.vue')
const changeAd = () => import(/* webpackChunkName: "homepage" */ '../components/Ad/changeAd/changeAd.vue')

const NewsMange = () => import(/* webpackChunkName: "news" */ '../components/NewsMange/NewsMange.vue')
const NewsInfo = () => import(/* webpackChunkName: "news" */ '../components/NewsMange/NewsInfo/NewsInfo.vue')
const Buylist = () => import(/* webpackChunkName: "news" */ '../components/NewsMange/BuyNew/buylist/Buylist.vue')
const SellList = () => import(/* webpackChunkName: "news" */ '../components/NewsMange/sellInfo/selllist/SellList.vue')
const AddNews = () => import(/* webpackChunkName: "news" */ '../components/NewsMange/NewsInfo/AdddNews/AddNews.vue')
const BuyNews = () => import(/* webpackChunkName: "news" */ '../components/NewsMange/BuyNew/buyNews/buyNews.vue')
const SellNews = () => import(/* webpackChunkName: "news" */ '../components/NewsMange/sellInfo/sellNews/sellNews.vue')

const Course = () => import(/* webpackChunkName: "course" */ '../components/Course/Course.vue')
const AddCourse = () => import(/* webpackChunkName: "course" */ '../components/Course/AddCourse/AddCourse.vue')
const CourseList = () => import(/* webpackChunkName: "course" */ '../components/Course/CourseList/CourseList.vue')
const CourseSort = () => import(/* webpackChunkName: "course" */ '../components/Course/CourseSort/CourseSort.vue')

const Chariman = () => import(/* webpackChunkName: "team" */ '../components/Chariman/Chariman.vue')
const CharimanInfo = () => import(/* webpackChunkName: "team" */ '../components/Chariman/CharimanInfo/CharimanInfo.vue')
// const AddChariman = () => import(/* webpackChunkName: "team" */ '../components/Chariman/AddChariman/AddChariman.vue')

const PayMange = () => import(/* webpackChunkName: "money" */ '../components/PayMange/PayMange.vue')
const PatMangeList = () => import(/* webpackChunkName: "money" */ '../components/PayMange/PatMangeList/PayMangeLisr.vue')
const ordercancle = () => import(/* webpackChunkName: "money" */ '../components/PayMange/PatMangeList/ordercancle.vue')

const SystomMange = () => import(/* webpackChunkName: "systom" */ '../components/Systom/SystomMange.vue')
const SystomList = () => import(/* webpackChunkName: "systom" */ '../components/Systom/SystomList/SystomList.vue')
const AddUser = () => import(/* webpackChunkName: "systom" */ '../components/Systom/AddUser/AddUser.vue')

// const Member = () => import(/* webpackChunkName: "group-foo" */ '../components/Member/Member.vue')
// const MemberList = () => import(/* webpackChunkName: "group-foo" */ '../components/Member/MemberList/MemberList.vue')
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    redirect: '/index',
    name: 'Home',
    component: Home,
    children: [{
      path: '/index',
      name: 'index',
      component: indexCom
    }, {
      path: '/goods',
      name: 'goods',
      redirect: '/goodslist',
      component: Goods,
      children: [{
        path: '/sortmange',
        name: 'SortMange',
        component: SortMange,
      }, {
        path: '/goodslist',
        name: 'GoodsList',
        component: GoodsList,
      }, {
        path: '/changegood/:isadd',
        name: 'AddGood',
        component: AddGoods,
      }]
    },
    {
      path: '/upgoods',
      name: 'upgoods',
      redirect: '/upgoodslist',

      component: UpGoods,
      children: [{
        path: '/upgoodslist',
        name: 'upgoodslist',
        component: UpGoodsList,
      }, {
        path: '/editgoodsinfo/:isadd',
        name: 'editgoodsinfo',
        component: UpeditGoodsInfo,
      }]
    },
    {
      path: '/teammange',
      name: 'TeamMange',
      redirect: '/teaminfo',
      component: TeamMange,
      children: [{
        path: '/teaminfo',
        name: 'TeamInfo',
        component: TeamInfo
      }, {
        path: '/addteam/:isadd',
        name: 'AddTeam',
        component: AddTeam
      }, {
        path: '/goodsmange/:actId',
        name: 'GoodsMange',
        component: GoodsMange
      }, {
        path: '/liveinfo',
        name: 'LiveList',
        component: LiveList
      }, {
        path: '/livegood/:isadd',
        name: 'Livegood',
        component: Livegood
      }, {
        path: '/livegoodsmange/:actId',
        name: 'LiveGoodsMange',
        component: LiveGoodsMange
      }]
    },
    {
      path: '/professionmange',
      name: 'ProfessionMange',
      redirect: '/professionlist',
      component: ProfessionMange,
      children: [{
        path: '/professionlist',
        name: 'ProfessionList',
        component: ProfessionList
      }, {
        path: '/changeprofess/:isadd',
        name: 'changeProfess',
        component: changeProfess
      }, {
        path: '/premange',
        name: 'preMange',
        component: preMange
      }]
    },
    {
      path: '/orderform',
      redirect: '/order/all',
      name: 'OrderForm',
      component: OrderForm,
      children: [{
        path: '/order/:statu',
        name: 'Order',
        component: Order
      }, {
        path: '/orderdetaile/:id',
        name: 'OrderDetaile',
        component: OrderDetaile
      }]
    },
    {
      path: '/swiper',
      name: 'SwiperMange',
      redirect: '/swiperinfo',
      component: SwiperMange,
      children: [{
        path: '/swiperinfo',
        name: 'SwiperInfo',
        component: SwiperInfo
      }, {
        path: '/changeswiper/:isadd',
        name: 'changeSwiper',
        component: changeSwiper
      }, {
        path: '/adinfo',
        name: 'AdInfo',
        component: AdInfo
      }, {
        path: '/changead/:isadd',
        name: 'changeAd',
        component: changeAd
      }]
    },
    {
      path: '/newsmange',
      name: 'NewsMange',
      redirect: '/newsinfo',
      component: NewsMange,
      children: [{
        path: '/newsinfo',
        name: 'NewsInfo',
        component: NewsInfo
      }, {
        path: '/addnews/:isadd',
        name: 'AddNews',
        component: AddNews
      }, {
        path: '/buynews/:isadd',
        name: 'BuyNews',
        component: BuyNews
      }, {
        path: '/paylist',
        name: 'SellList',
        component: SellList
      }, {
        path: '/buylist',
        name: 'Buylist',
        component: Buylist
      },
      {
        path: '/paynews/:isadd',
        name: 'SellNews',
        component: SellNews
      }]
    }, {
      path: '/coursemange',
      name: 'Course',
      redirect: '/courselist',
      component: Course,
      children: [{
        path: '/courselist',
        name: 'CourseList',
        component: CourseList
      }, {
        path: '/addcourses/:isadd',
        name: 'AddCourse',
        component: AddCourse
      }, {
        path: '/coursesort',
        name: 'CourseSort',
        component: CourseSort
      }]
    },
    {
      path: '/authorzationmange',
      name: 'authorzationMange',
      redirect: '/authorzationlist',
      component: authorzationMange,
      children: [{
        path: '/authorzationlist',
        name: 'AuthorzationList',
        component: AuthorzationList
      }, {
        path: '/cancleorderauth',
        name: 'cancleorderAuthorzationList',
        component: cancleorderAuthorzationList
      },
      {
        path: '/cancledetaile/:id',
        name: 'cancledetaile',
        component: cancledetaile
      },
      {
        path: '/cancleorderitem',
        name: 'cancleorderitem',
        component: cancleorderitem
      }
      ]
    }, {
      path: '/chairmanmange',
      name: 'Chariman',
      redirect: '/charimaninfo',
      component: Chariman,
      children: [{
        path: '/charimaninfo',
        name: 'CharimanInfo',
        component: CharimanInfo
      }
      ]
    }, {
      path: '/paymange',
      name: 'PayMange',
      redirect: '/paymangelist',
      component: PayMange,
      children: [{
        path: '/paymangelist',
        name: 'PatMangeList',
        component: PatMangeList
      }, {
        path: '/ordercancle',
        name: 'ordercancle',
        component: ordercancle
      }]
    }, {
      path: '/systommange',
      name: 'SystomMange',
      redirect: '/systomlist',
      component: SystomMange,
      children: [{
        path: '/systomlist',
        name: 'SystomList',
        component: SystomList
      }, {
        path: '/adduser/:isadd',
        name: 'AddUser',
        component: AddUser
      }]
    }]
  }]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const tokenstr = window.sessionStorage.getItem('authToken')
  if (!tokenstr) return next('/login')
  next()
})

export default router
